const FAQ = () => {
    const faqs = [
      {
        question: 'When will boxes be available?',
        answer: 'Boxes will be available 2nd week of December, starting December 14th.',
      },
      {
        question: 'Where can I pickup my box?!',
        answer:
          'Local pickup at Duwamish Longhouse, Dec 14th (11am-7pm) with other pickup locations/days TBD.',
      },
      {
        question: 'When should I purchase the boxes?',
        answer: 'We recommend you pre-order!',
      },
      {
        question: 'How many boxes are there for sale?',
        answer: 'Limited quantities!! Buy yours now before they sell out!',
      },
      {
        question: 'Is shipping available?',
        answer:
          'Shipping is available!! Just select the region where you need it shipped & fees are built in.',
      },
    ];
  
    return (
      <div className="bg-gray-100 p-6 sm:p-12 rounded-lg max-w-5xl mx-auto mt-10">
        <h2 className="text-2xl font-bold text-white text-center mb-8">Frequently Asked Questions</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {faqs.slice(0, 4).map((faq, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:-translate-y-1 hover:shadow-xl"
            >
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{faq.question}</h3>
              <p className="text-gray-700">{faq.answer}</p>
            </div>
          ))}
          <div className="col-span-full md:col-span-2 flex justify-center">
            <div
              className="bg-white p-6 rounded-lg shadow-lg transition-transform transform hover:-translate-y-1 hover:shadow-xl w-full md:w-2/3"
            >
              <h3 className="text-lg font-semibold text-gray-800 mb-2">{faqs[4].question}</h3>
              <p className="text-gray-700">{faqs[4].answer}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default FAQ;
  